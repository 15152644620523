import { render, staticRenderFns } from "./Videoroom.vue?vue&type=template&id=1329fa2e&scoped=true&"
import script from "./Videoroom.vue?vue&type=script&lang=js&"
export * from "./Videoroom.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Videoroom.vue?vue&type=style&index=0&id=1329fa2e&lang=css&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1329fa2e",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
