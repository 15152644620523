import { render, staticRenderFns } from "./Textroom.vue?vue&type=template&id=1c4fa765&scoped=true&"
import script from "./Textroom.vue?vue&type=script&lang=js&"
export * from "./Textroom.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Textroom.vue?vue&type=style&index=0&id=1c4fa765&lang=css&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1c4fa765",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VList,VListItem,VListItemTitle,VMenu,VTextField})
