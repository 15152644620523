import { render, staticRenderFns } from "./Echorooms.vue?vue&type=template&id=23796e4f&scoped=true&"
import script from "./Echorooms.vue?vue&type=script&lang=js&"
export * from "./Echorooms.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Echorooms.vue?vue&type=style&index=0&id=23796e4f&lang=css&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "23796e4f",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
