import { render, staticRenderFns } from "./Videocall.vue?vue&type=template&id=5b2a885a&scoped=true&"
import script from "./Videocall.vue?vue&type=script&lang=js&"
export * from "./Videocall.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Videocall.vue?vue&type=style&index=0&id=5b2a885a&lang=css&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5b2a885a",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
